import * as React from 'react'
import Layout from '../components/layout'
import { styles } from "../shared-styles";

const AboutPage = () => {
  return (
    <Layout pageTitle="Despre noi">
      <section style={styles.ContentSection}>
        <h3>SCURTĂ DESCRIERE</h3>
        <p>Asocierea celor doua cabinete a decurs de la sine, doamna Anamaria Crețu fiind fiica doamnei Carmen Hoblea. Profesia de avocat apare ca o menire în acestă familie, mama doamnei Carmen Hoblea fiind, la rândul său, juristă.</p>
        <p>Suntem ghidate, în acestă profesie, de credința în dreptate si în justiție. Am observat, de-a-lungul anilor, că munca avocaților este indispensabilă înfăptuirii justiției, cel mai important aspect fiind modul în care sunt concepute și formulate acțiunile civile, înainte de a se ajunge în faza de judecată.</p>
        <p>Apreciem că o pregătire juridică temeinică și continuă este necesară pentru a putea garanta clienților o apărare solidă, motiv pentru care punem un mare accent pe partea teoretică, fără de care latura practică a avocaturii nu se poate ridica la standarde înalte.</p>
      </section>

    </Layout>
  )
}

export default AboutPage
